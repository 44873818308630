'use strict';

angular.module('enervexSalesappApp').factory('StackRuleConditionType', function ($resource) {
	return $resource('/api/stack-rule-condition-types/:id', {
			id: '@_id'
		},
		{

		});
});
